import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helper';
import { GET_APPLICANT, DELETE_APPLICANT, UPDATE_APPLICANT, APPLICANT_SENT_INVITE } from '../action/types';
import {
    getApplicantSuccess,
    getApplicantFailure,
    deleteApplicantSuccess,
    deleteApplicantFailure,
    updateApplicantSuccess,
    updateApplicantFailure,
    applicantSentInviteSuccess,
    applicantSentInviteFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';

// Helper for toast error
const handleError = (e) => {
    const errorMessage = e?.response?.data?.message;
    if (errorMessage && errorMessage !== 'Your session has been expired, please login again!') {
        toast.error(<Toast msg={errorMessage} />, {
            autoClose: 5000,
            toastId: errorMessage,
        });
    }
};

// Helper to create filters for GET request
const createFilters = (payload) => {
    const { search, page, pageSize, sort, filter, product } = payload;
    const isSearch = search ? `&search=${encodeURIComponent(search)}` : '';
    const isPageAndPageSize = page && pageSize ? `?page=${page}&pageSize=${pageSize}` : '';
    const isSort = sort || (filter?.sort ? `&sort=${filter.sort}` : '');
    const isProduct = product ? `&product=${product}` : '';

    const isFilter = filter
        ? [
              filter.from ? `&from=${filter.from}T00:00:00&to=${filter.to || '3000-12-31'}T23:59:59` : '',
              filter.qFrom && filter.qTo ? `&qFrom=${filter.qFrom}&qTo=${filter.qTo}` : '',
              filter.status ? `&status=${filter.status}` : '',
              filter.createdBy ? `&createdBy=${filter.createdBy}` : '',
          ]
              .filter(Boolean)
              .join('')
        : '';

    return `${isPageAndPageSize}${isSearch}${isFilter}${isSort}${isProduct}`;
};

function* getApplicantRequest(action) {
    try {
        const filters = createFilters(action.payload);
        const res = yield INTERVIEW.get(`applicants/getApplicants${filters}`);

        if ([200, 201].includes(res.status)) {
            if (action.payload.page === 'all') {
                yield put(getApplicantFailure());
                yield call(action.payload.callback, res.data.result);
            } else {
                yield put(getApplicantSuccess(res.data.result));
            }
        } else {
            yield put(getApplicantFailure());
        }
    } catch (e) {
        yield put(getApplicantFailure());
        handleError(e);
    }
}

function* updateApplicantRequest(action) {
    try {
        const {
            position,
            candidate = {},
            resume = '',
            noticePeriod,
            applicantId = '',
            notes,
            expectedCTC,
            currentCTC,
        } = action.payload;
        let resType = typeof resume;
        const formData = new FormData();
        formData.append('applicantId', applicantId);
        formData.append('position', position);
        formData.append('candidate', JSON.stringify(candidate));
        if (resType !== 'string') formData.append('resume', resume);
        formData.append('noticePeriod', noticePeriod);
        formData.append('currentCTC', currentCTC);
        formData.append('expectedCTC', expectedCTC);
        formData.append('notes', notes);
        const res = yield INTERVIEW.post(`applicants/updateApplicant`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
        });

        if (res.status === 201 && res.data.result?.status) {
            yield put(updateApplicantSuccess());
            yield call(action.payload.callback);
            toast.success(<Toast msg="Applicant updated successfully." />, {
                autoClose: 5000,
                toastId: 'Applicant updated successfully.',
            });
        } else {
            yield put(updateApplicantFailure());
            yield* handleError(res);
        }
    } catch (e) {
        yield put(updateApplicantFailure());
        handleError(e);
    }
}

function* applicantSentInviteRequest(action) {
    try {
        const res = yield INTERVIEW.post('applicants/sentInvite', action.payload.data);
        if (res.status === 201 && res.data.result?.status) {
            yield put(applicantSentInviteSuccess());
            yield call(action.payload.callback);
            toast.success(<Toast msg="Invite sent successfully." />, {
                autoClose: 5000,
                toastId: 'Invite sent successfully.',
            });
        } else {
            yield put(applicantSentInviteFailure());
            yield* handleError(res);
        }
    } catch (e) {
        yield put(applicantSentInviteFailure());
        handleError(e);
    }
}

function* deleteApplicantRequest(action) {
    try {
        const res = yield INTERVIEW.delete(`applicant/${action.payload.applicantId}`);

        if (res.status === 204) {
            yield put(deleteApplicantSuccess());
            yield call(action.payload.callback);
            toast.success(<Toast msg="Applicant deleted successfully." />, {
                autoClose: 5000,
                toastId: 'Applicant deleted successfully.',
            });
        } else {
            yield put(deleteApplicantFailure());
            yield* handleError(res);
        }
    } catch (e) {
        yield put(deleteApplicantFailure());
        handleError(e);
    }
}

export function* watchGetApplicantAPI() {
    yield takeEvery(GET_APPLICANT, getApplicantRequest);
}

export function* watchDeleteApplicantAPI() {
    yield takeEvery(DELETE_APPLICANT, deleteApplicantRequest);
}

export function* watchUpdateApplicantAPI() {
    yield takeEvery(UPDATE_APPLICANT, updateApplicantRequest);
}

export function* watchApplicantSentInviteAPI() {
    yield takeEvery(APPLICANT_SENT_INVITE, applicantSentInviteRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetApplicantAPI(),
        watchDeleteApplicantAPI(),
        watchUpdateApplicantAPI(),
        watchApplicantSentInviteAPI(),
    ]);
}
