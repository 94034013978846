import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, localStorageJsonParseDecrypt } from '../../utils/helper';

import {
    GET_DOMAIN,
    GET_MANDATORY_SKILL,
    GET_OPTIONAL_SKILL,
    GET_SCREENING_QUESTIONS,
    CREATE_JD,
    CREATE_OR_UPDATE_POSITION,
    GET_POSITION,
    DELETE_POSITION,
    GET_SHORTLISTED_CANDIDATES,
    GET_POSITION_LIST,
    DELETE_SCREENING_QUESTIONS,
    GET_AI_SCREENING_QUESTIONS,
    COMPARE_JD_CV,
    GET_DROPDOWN_POSITION,
    CHANGE_POSITION_STATUS,
    GET_GUEST_LIST,
    GET_WHITELABEL,
    MAKE_POSITION_FROM_INCBOT,
    GET_LATEST_ACTIVE_POSITIONS,
    FETCH_SKILLS,
    FETCH_QUESTIONS,
} from '../action/types';
import {
    getDomainSuccess,
    getDomainFailure,
    getMandatorySkillSuccess,
    getMandatorySkillFailure,
    getOptionalSkillSuccess,
    getOptionalSkillFailure,
    getScreeningQuestionsSuccess,
    getScreeningQuestionsFailure,
    createOrUpdatePositionSuccess,
    createOrUpdatePositionFailure,
    getPositionSuccess,
    getPositionFailure,
    getPosition,
    deletePositionSuccess,
    deletePositionFailure,
    getshortlistedCandidatesSuccess,
    getshortlistedCandidatesFailure,
    getPositionListSuccess,
    getPositionListFailure,
    deleteScreeningQuestionSuccess,
    deleteScreeningQuestionFailure,
    getAiScreeningQuestionsSuccess,
    getAiScreeningQuestionsFailure,
    createJDSuccess,
    createJDFailure,
    compareJDCVSuccess,
    compareJDCVFailure,
    getDropdownPositionSuccess,
    getDropdownPositionFailure,
    changePositionStatusSuccess,
    changePositionStatusFailure,
    getGuestListSuccess,
    getGuestListFailure,
    getWhitelabelSuccess,
    getWhitelabelFailure,
    makePositionFromIncbotSuccess,
    makePositionFromIncbotFailure,
    getLatestActivePositionsSuccess,
    getLatestActivePositionsFailure,
    fetchSkillsSuccess,
    fetchSkillsFailure,
    fetchQuestionsSuccess,
    fetchQuestionsFailure,
} from '../action';
import DOMAIN from '../../utils/api/domain';
import POSITIONS from '../../utils/api/positions';
import SKILLS from '../../utils/api/skills';
import INTERVIEW from '../../utils/api/interview';
import USERS from '../../utils/api/users';
import SCREENINGQUESTIONS from '../../utils/api/screeningquestions';
import { PageSize } from '../../utils/constant';

function* fetchQuestionsRequest(action) {
    try {
        const res = yield SKILLS.post(`screeningquestions/questionBank`, action.payload.data);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchQuestionsSuccess());
            if (res.data.result?.error || res.data.result?.message) {
                const message = res.data.result?.error || res.data.result?.message;
                yield toast.error(<Toast msg={message} />, {
                    autoClose: 5000,
                    toastId: message,
                });
            } else {
                yield call(action.payload.callback(res.data.result));
            }
        }
    } catch (e) {
        yield put(fetchQuestionsFailure());
    }
}

function* fetchSkillsRequest(action) {
    try {
        const res = yield SKILLS.post(`skills/fetch-skills`, action.payload.data);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchSkillsSuccess());
            if (res.data.result?.error || res.data.result?.message) {
                const message = res.data.result?.error || res.data.result?.message;
                yield toast.error(<Toast msg={message} />, {
                    autoClose: 5000,
                    toastId: message,
                });
            } else {
                yield call(action.payload.callback(res.data.result));
            }
        }
    } catch (e) {
        yield put(fetchSkillsFailure());
    }
}

function* getDomainRequest() {
    try {
        const res = yield DOMAIN.get('domains');
        if (res.status === 200) {
            yield put(getDomainSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getDomainFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getWhitelabelRequest() {
    try {
        const res = yield USERS.get('employers/employerLogo');
        if (res.status === 200) {
            yield put(getWhitelabelSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getWhitelabelFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getPositionListRequest(action) {
    try {
        const res = yield POSITIONS.get(`positionlists`);
        if (res.status === 200) {
            yield put(getPositionListSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPositionListFailure());
    }
}

function* getMandatorySkillRequest() {
    try {
        const res = yield SKILLS.get(`skills?type=mandatory`);
        if (res.status === 200) {
            yield put(getMandatorySkillSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getMandatorySkillFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getOptionalSkillRequest() {
    try {
        const res = yield SKILLS.get(`skills?type=optional`);
        if (res.status === 200) {
            yield put(getOptionalSkillSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getOptionalSkillFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getScreeningQuestionRequest() {
    try {
        let product = localStorageJsonParseDecrypt('productId')?.productId;
        let res;
        if (product) {
            res = yield SCREENINGQUESTIONS.get(`screeningquestions?product=${product}`);
        } else {
            res = yield SCREENINGQUESTIONS.get(`screeningquestions`);
        }
        if (res.status === 200) {
            yield put(getScreeningQuestionsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getScreeningQuestionsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* createJDRequest(action) {
    try {
        const res = yield INTERVIEW.get(
            `interviews/createJobDescription?position=${action.payload.position}&experience=${action.payload.experience}`
        );
        if (res.status === 201 || res.status === 200) {
            yield put(createJDSuccess(res.data.result));
        }
    } catch (e) {
        yield put(createJDFailure());
    }
}

function* compareJDCVRequest(action) {
    let formData = new FormData();
    formData.append('jd', action.payload.jd);
    formData.append('cv', action.payload.cv);
    try {
        const res = yield INTERVIEW.post(`interviews/compareJDToCV`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            yield put(compareJDCVSuccess(res.data));
            // const regex = /(\d+)/;
            const percentage = Number(res.data.matchingPercentage);
            if (percentage <= 0) {
                toast.error(<Toast msg={'Unable to parse resume'} />, { toastId: 'Unable to parse resume' });
            }
        }
    } catch (e) {
        yield put(compareJDCVFailure());
        toast.error(<Toast msg={'Unable to parse resume'} />, { toastId: 'Unable to parse resume' });
    }
}

function* createPositionRequest(action) {
    try {
        let res;
        const formData = new FormData();
        formData.append('title', action.payload.data.title);
        if (action.payload.data.jobLocation) formData.append('jobLocation', action.payload.data.jobLocation);
        if (action.payload.data.domain) formData.append('domain', action.payload.data.domain);
        if (action.payload.data.jobType) formData.append('jobType', action.payload.data.jobType);
        if (action.payload.data.salaryRange) formData.append('salaryRange', action.payload.data.salaryRange);
        formData.append('noOfPositions', action.payload.data.noOfPositions);
        formData.append('expRange', JSON.stringify(action.payload.data.expRange));
        formData.append('jobDescription', action.payload.data.jobDescription);
        formData.append('skills', JSON.stringify(action.payload.data.skills));
        formData.append('customSkills', JSON.stringify(action.payload.data.customSkills));
        formData.append('screeningQuestions', JSON.stringify(action.payload.data.screeningQuestions));
        formData.append('customScreeningQuestions', JSON.stringify(action.payload.data.customScreeningQuestions));
        formData.append(
            'customField',
            JSON.stringify(action.payload.data.customField ? action.payload.data.customField : [])
        );
        formData.append('remarks', action.payload.data.remarks);
        formData.append('product', localStorageJsonParseDecrypt('productId')?.productId);
        let message;
        if (action?.payload?.id) {
            res = yield POSITIONS.put(`positions/${action?.payload?.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            message = 'Position updated successfully.';
        } else {
            res = yield POSITIONS.post('positions', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            message = 'Position created successfully.';
        }
        if (res.status === 201 || res.status === 200) {
            yield put(createOrUpdatePositionSuccess());
            yield toast.success(<Toast msg={`${message}`} />);
            yield call(action.payload.callback(res.data.result));
        }
    } catch (e) {
        if (e?.response?.status === 400) {
            yield put(createOrUpdatePositionFailure(e?.response?.data?.message));
            yield e?.response?.data?.message &&
                e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
                toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
        } else {
            yield put(createOrUpdatePositionFailure());
            yield e?.response?.data?.message &&
                e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
                toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
        }
    }
}

function* getPositionRequest(action) {
    const productId = localStorageJsonParseDecrypt('productId')?.productId;
    const isSearch = action.payload.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
    const isSort = action.payload.filters
        ? action.payload.filters.sort
            ? `&sort=${action.payload.filters.sort}`
            : action.payload.sort
              ? `&sort=${action.payload.sort}`
              : ''
        : action.payload.sort
          ? `&sort=${action.payload.sort}`
          : '';
    const isPageAndPageSize =
        action.payload.page && action.payload.pageSize
            ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
            : '';
    const isfilter = action.payload.filters
        ? (action.payload.filters.from ? `&from=${action.payload.filters.from}T00:00:00` : '') +
          (action.payload.filters.to ? `&to=${action.payload.filters.to}T23:59:59` : '') +
          (action.payload.filters.createdBy ? `&createdBy=${action.payload.filters.createdBy}` : '') +
          (action.payload.filters.experience ? `&experience=${action.payload.filters.experience}` : '')
        : '';
    const product = isPageAndPageSize ? `&product=${productId}` : `?product=${productId}`;
    try {
        const res = yield POSITIONS.get(`positions${isPageAndPageSize}${isSearch}${isfilter}${product}${isSort}`);
        if (res.status === 200) {
            yield put(getPositionSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getDropdownPositionRequest(action) {
    const productId = localStorageJsonParseDecrypt('productId')?.productId;
    try {
        const res = yield POSITIONS.get(
            `positions/dropdown?product=${productId}&bypass=${action?.payload?.bypass ? 'true' : 'false'}`
        );
        if (res.status === 200) {
            yield put(getDropdownPositionSuccess(res.data.result));
            if (action?.payload?.callback) {
                yield call(action?.payload?.callback);
            }
        }
    } catch (e) {
        yield put(getDropdownPositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* changePositionStatusRequest(action) {
    try {
        const res = yield POSITIONS.put(
            `positions/changeStatus/${action?.payload?.data?.id}?status=${action?.payload?.data?.status}&notify=${action.payload.notify}`
        );
        if (res.status === 200) {
            yield put(changePositionStatusSuccess(res.data.result));
            yield call(action.payload.callback);
            toast.success(
                <Toast
                    msg={`${
                        res.data.result.status === 'closed'
                            ? 'Position Closed Successfully'
                            : 'Position Reopen Successfully'
                    }`}
                />
            );
        }
    } catch (e) {
        yield put(changePositionStatusFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* deletePositionRequest(action) {
    try {
        const res = yield POSITIONS.delete(`positions/${action.payload.deleteId} `);
        if (res.status === 204) {
            yield put(deletePositionSuccess());
            yield put(getPosition({ page: 1, pageSize: PageSize }));
            yield call(action.payload.callback);
            toast.success(<Toast msg={'Position Deleted Successfully'} />);
        }
    } catch (e) {
        yield put(deletePositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* deleteScreeningQuestionRequest(action) {
    try {
        const res = yield SCREENINGQUESTIONS.delete(`screeningquestions/${action.payload.deleteId} `);
        if (res.status === 204) {
            yield put(deleteScreeningQuestionSuccess());
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(deleteScreeningQuestionFailure());
    }
}

function* getshortlistedCandidatesRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/getshortlisted/${action.payload.positionId} `);
        if (res.status === 200) {
            yield put(getshortlistedCandidatesSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(getshortlistedCandidatesFailure());
        yield call(action.payload.callback);
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, { toastId: e?.response?.data?.message });
    }
}

function* getAiScreeningQuestionsRequest(action) {
    let descriptionType = typeof action?.payload?.data;
    let formData = new FormData();
    if (descriptionType !== 'string') {
        formData.append('jd', action.payload.data);
    }
    try {
        const res = yield INTERVIEW.post(`interviews/generate-screening-questions`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            yield put(getAiScreeningQuestionsSuccess(res));
        }
    } catch (e) {
        yield put(getAiScreeningQuestionsFailure());
    }
}

function* getGuestListRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/inviteMorePanelist?product=${action.payload}`);
        if (res.status === 200) {
            yield put(getGuestListSuccess(res?.data?.result?.data));
        }
    } catch (e) {
        yield put(getGuestListFailure());
    }
}

function* makePositionFromIncbotRequest(action) {
    try {
        const res = yield POSITIONS.get(
            `positions/fetchPosition/${action?.payload?.positionId}/${action?.payload?.product}`
        );
        if (res.status === 200 || res.status === 201) {
            yield put(makePositionFromIncbotSuccess(res?.data?.result));
            if (action?.payload?.callback) {
                yield call(action?.payload?.callback);
            }
        } else yield put(makePositionFromIncbotFailure());
    } catch (e) {
        yield put(makePositionFromIncbotFailure());
    }
}

function* getLatestActivePositionsRequest() {
    try {
        const productId = localStorageJsonParseDecrypt('productId')?.productId;
        const res = yield POSITIONS.get(
            `positions?page=1&pageSize=5&product=${productId}&status=active&sort=createdAt%20desc`
        );
        if (res.status === 201 || res.status === 200) {
            yield put(getLatestActivePositionsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getLatestActivePositionsFailure());
    }
}

export function* watchGetDomainAPI() {
    yield takeEvery(GET_DOMAIN, getDomainRequest);
}

export function* watchGetWhitelabelAPI() {
    yield takeEvery(GET_WHITELABEL, getWhitelabelRequest);
}

export function* watchGetMandatorySkillAPI() {
    yield takeEvery(GET_MANDATORY_SKILL, getMandatorySkillRequest);
}

export function* watchGetOptionalSkillAPI() {
    yield takeEvery(GET_OPTIONAL_SKILL, getOptionalSkillRequest);
}

export function* watchGetScreeningQuestionAPI() {
    yield takeEvery(GET_SCREENING_QUESTIONS, getScreeningQuestionRequest);
}

export function* watchDeleteScreeningQuestionAPI() {
    yield takeEvery(DELETE_SCREENING_QUESTIONS, deleteScreeningQuestionRequest);
}

export function* watchCreateOrUpdatePositionAPI() {
    yield takeEvery(CREATE_OR_UPDATE_POSITION, createPositionRequest);
}

export function* watchGetPositionAPI() {
    yield takeEvery(GET_POSITION, getPositionRequest);
}
export function* watchGetDropdownPositionAPI() {
    yield takeEvery(GET_DROPDOWN_POSITION, getDropdownPositionRequest);
}

export function* watchDeletePositionAPI() {
    yield takeEvery(DELETE_POSITION, deletePositionRequest);
}

export function* watchCreateJDAPI() {
    yield takeEvery(CREATE_JD, createJDRequest);
}

export function* watchGetShortlistedAPI() {
    yield takeEvery(GET_SHORTLISTED_CANDIDATES, getshortlistedCandidatesRequest);
}

export function* watchGetPositionListAPI() {
    yield takeEvery(GET_POSITION_LIST, getPositionListRequest);
}

export function* watchGetAiScreeningQuestionAPI() {
    yield takeEvery(GET_AI_SCREENING_QUESTIONS, getAiScreeningQuestionsRequest);
}
export function* watchCompareJDCVAPI() {
    yield takeEvery(COMPARE_JD_CV, compareJDCVRequest);
}
export function* watchChangePositionStatusAPI() {
    yield takeEvery(CHANGE_POSITION_STATUS, changePositionStatusRequest);
}

export function* watchGetGuestListAPI() {
    yield takeEvery(GET_GUEST_LIST, getGuestListRequest);
}

export function* watchMakePositionFromIncbotAPi() {
    yield takeEvery(MAKE_POSITION_FROM_INCBOT, makePositionFromIncbotRequest);
}

export function* watchGetLatestActivePositions() {
    yield takeEvery(GET_LATEST_ACTIVE_POSITIONS, getLatestActivePositionsRequest);
}
export function* watchFetchSkillsAPI() {
    yield takeEvery(FETCH_SKILLS, fetchSkillsRequest);
}
export function* watchFetchQuestionsAPI() {
    yield takeEvery(FETCH_QUESTIONS, fetchQuestionsRequest);
}

export default function* rootSaga() {
    yield all([
        watchChangePositionStatusAPI(),
        watchGetDomainAPI(),
        watchGetWhitelabelAPI(),
        watchGetMandatorySkillAPI(),
        watchGetOptionalSkillAPI(),
        watchGetScreeningQuestionAPI(),
        watchDeleteScreeningQuestionAPI(),
        watchCreateOrUpdatePositionAPI(),
        watchCreateJDAPI(),
        watchGetPositionAPI(),
        watchGetDropdownPositionAPI(),
        watchGetPositionListAPI(),
        watchDeletePositionAPI(),
        watchGetShortlistedAPI(),
        watchGetAiScreeningQuestionAPI(),
        watchCompareJDCVAPI(),
        watchGetGuestListAPI(),
        watchMakePositionFromIncbotAPi(),
        watchGetLatestActivePositions(),
        watchFetchSkillsAPI(),
        watchFetchQuestionsAPI(),
    ]);
}
