import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast, localStorageEncrypt, localStorageJsonParseDecrypt, localStorageRemove } from '../../utils/helper';

import {
    POST_INTERVIEW,
    POST_BULK_INTERVIEW,
    GET_INTERVIEW,
    GET_INTERVIEW_STAT,
    GET_POSITION_STAT,
    UPDATE_INTERVIEW_STATUS,
    GET_FEEDBACK,
    CREATE_CALENDAR_INVITATION,
    GET_FEEDBACK_REPORT,
    GET_INTERVIEW_BY_ID,
    GET_INTERVIEW_BY_ID_FOR_POSITION,
    CHECK_CANDIDATE_EXISTS,
    GET_PANELIST_CALENDAR_REQUEST,
    GET_CANDIDATE_CALENDAR_REQUEST,
    GET_CANDIDATE_DETAILS_USING_RESUME,
    ADD_CUSTOM_FILE,
    CONTINUE_CALENDAR_INVITATION,
    GET_POSITION_STAT_FOR_BAR_GRAPH,
    RESEND_EMAIL,
    FETCH_RESUME_SCORE,
    GET_INCVID_PLATFORM_PERMISSION,
    GET_INTERVIEW_INCBOT,
    GET_INTERVIEW_DATA,
} from '../action/types';
import {
    postInterviewSuccess,
    postInterviewFailure,
    postBulkInterviewSuccess,
    postBulkInterviewFailure,
    getInterviewSuccess,
    getInterviewFailure,
    getPositionStatSuccess,
    getPositionStatFailure,
    getInterviewStatSuccess,
    getInterviewStatFailure,
    updateInterviewStatusSuccess,
    updateInterviewStatusFailure,
    getFeedbackSuccess,
    getFeedbackFailure,
    createCalendarInviteSuccess,
    createCalendarInviteFailure,
    getFeedbackReportSuccess,
    getFeedbackReportFailure,
    getInterviewByIdSuccess,
    getInterviewByIdFailure,
    getInterviewByIdForPositionSuccess,
    getInterviewByIdForPositionFailure,
    checkCandidateExistsSuccess,
    checkCandidateExistsFailure,
    getPanelistCalendarRequestSuccess,
    getCandidateCalendarRequestSuccess,
    getPanelistCalendarRequestFailure,
    getCandidateCalendarRequestFailure,
    getCandidateCalendarRequest,
    getCandidateDetailsUsingResumeSuccess,
    getCandidateDetailsUsingResumeFailure,
    addCustomFileSuccess,
    addCustomFileFailure,
    continueCalendarInviteSuccess,
    continueCalendarInviteFailure,
    getPositionStatForBarGraphSuccess,
    getPositionStatForBarGraphFailure,
    resendEmailSuccess,
    resendEmailFailure,
    fetchResumeScoreSuccess,
    fetchResumeScoreFailure,
    incvidPlatformPermissionSuccess,
    incvidPlatformPermissionFailure,
    getInterviewIncbotSuccess,
    getInterviewIncbotFailure,
    getInterviewDataSuccess,
    getInterviewDataFailure,
} from '../action';
import INTERVIEW from '../../utils/api/interview';
import INTERVIEW_INCBOT from '../../utils/api/interviewIncbot';
import CANDIDATE from '../../utils/api/candidate';
import CALENDAR from '../../utils/api/calendar';
function* fetchResumeScoreRequest(action) {
    try {
        const res = yield INTERVIEW.post('interviews/fetchResumeScore', action?.payload);
        if (res.status === 201 || res.status === 200) {
            yield put(fetchResumeScoreSuccess(res.data.result));
        }
    } catch (e) {
        yield put(fetchResumeScoreFailure());
    }
}
function* getPanelistCalendar(action) {
    try {
        const res = yield CALENDAR.post(`calendar/requestSlot/interviewer`, action.payload.data);
        if (res.status === 201) {
            yield put(getPanelistCalendarRequestSuccess(res.data?.result));
            yield toast.success(<Toast msg="Panelist notified successfully." />);
        }
    } catch (e) {
        yield put(getPanelistCalendarRequestFailure());
    }
}

function* getCandidateDetailsUsingResumeRequest(action) {
    let formData = new FormData();
    formData.append('cv', action.payload.resume);
    try {
        const res = yield INTERVIEW.post(`interviews/extractDataFromResume`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            yield put(getCandidateDetailsUsingResumeSuccess(res.data));
        }
    } catch (e) {
        yield put(getCandidateDetailsUsingResumeFailure());
    }
}

function* getCustomFileDetailsUsingResumeRequest(action) {
    let formData = new FormData();
    formData.append('file', action.payload.file);
    try {
        const res = yield INTERVIEW.post(`interviews/customField/fileUpload`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 201 || res.status === 200) {
            let result = { ...res.data.result, index: action.payload.index };

            yield put(addCustomFileSuccess(result));
        }
    } catch (e) {
        yield put(addCustomFileFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={'Could not read file, Please try uploading a PDF file.'} />, {
                toastId: 'Could not read file, Please try uploading a PDF file.',
            });
    }
}

function* getCandidateCalendar(action) {
    try {
        const res = yield CALENDAR.post(`calendar/requestSlot/candidate`, action.payload.data);
        if (res.status === 201) {
            yield put(getCandidateCalendarRequestSuccess(res.data?.result));
        }
    } catch (e) {
        yield put(getCandidateCalendarRequestFailure());
    }
}

function* postInterviewRequest(action) {
    let res;
    const { applicantId, position, resumeScore = 0, checkResumeScore = false, remarks } = action.payload.data;
    const { candidate, skills, panelist, guests, schedulingType, interviewDate, interviewTime } = action.payload.data;
    const { timeDuration, customScreeningQuestions, customSkills, screeningQuestions, candidateId } =
        action.payload.data;
    const { incBotId, customField, productId, jobDescription, resume, domain } = action.payload.data;
    const { currentCTC, expectedCTC, noticePeriod, notes } = action.payload.data;
    const formData = new FormData();
    if (applicantId) formData.append('applicantId', applicantId);
    formData.append('position', position);
    formData.append('resumeScore', resumeScore);
    formData.append('checkResumeScore', checkResumeScore);
    formData.append('remarks', remarks);
    formData.append('candidate', JSON.stringify(candidate));
    formData.append('skills', JSON.stringify(skills));
    formData.append('panelist', panelist);
    formData.append('guests', JSON.stringify(guests));
    if (schedulingType === 'manual' || schedulingType === 'custom') {
        let dateFormat = interviewDate.split('T');
        dateFormat = dateFormat[0];
        dateFormat = dateFormat.split('-');
        let year;
        if (dateFormat[0].length === 4) {
            year = dateFormat[0];
        } else {
            year = dateFormat[2];
        }
        let day;
        if (dateFormat[0].length === 2) {
            day = dateFormat[0];
        } else {
            day = dateFormat[2];
        }
        dateFormat = `${year}-${dateFormat[1]}-${day}T00:00:00`;
        formData.append('interviewDate', dateFormat);
        formData.append('interviewTime', interviewTime);
    }
    formData.append('schedulingType', schedulingType);
    formData.append('timeDuration', timeDuration);
    formData.append('customScreeningQuestions', JSON.stringify(customScreeningQuestions));
    formData.append('customSkills', JSON.stringify(customSkills));
    formData.append('screeningQuestions', JSON.stringify(screeningQuestions));
    formData.append('domain', domain);
    let resType = typeof resume;
    let descriptionType = typeof jobDescription;
    if (candidateId) formData.append('candidateId', candidateId);
    if (resType !== 'string') formData.append('resume', resume);
    if (descriptionType !== 'string') formData.append('jobDescription', jobDescription);
    formData.append('productId', productId);
    formData.append('customField', JSON.stringify(customField || []));
    if (currentCTC) formData.append('currentCTC', currentCTC);
    if (expectedCTC) formData.append('expectedCTC', expectedCTC);
    if (noticePeriod) formData.append('noticePeriod', noticePeriod);
    if (notes) formData.append('notes', notes);
    if (incBotId) formData.append('incbotId', incBotId);
    let message;
    try {
        if (action?.payload?.id && !action.payload.isReplicate) {
            res = yield INTERVIEW.put(`interviews/${action.payload.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            message = 'Interview updated successfully.';
        } else {
            res = yield INTERVIEW.post(`interviews`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            message = 'Interview created successfully.';
        }
        if (res.status === 201 || res.status === 200) {
            yield put(postInterviewSuccess());
            if (schedulingType === 'automated') {
                const companyData = localStorageJsonParseDecrypt('companyData');
                yield put(
                    getCandidateCalendarRequest({
                        data: {
                            name: action.payload.data.candidate.name,
                            email: action.payload.data.candidate.email,
                            phone: action.payload.data.candidate.phone,
                            companyName: companyData?.company?.name,
                            companyEmail: companyData?.company?.email,
                            interviewId: res.data.result?._id,
                            positionName: action.payload.data.positionName,
                            panelistId: action.payload.data?.panelistId,
                        },
                    })
                );
            }
            yield call(action.payload.callback, res.data.result);
            yield toast.success(<Toast msg={`${message}`} />);
        }
    } catch (e) {
        const errorMessage = e?.response?.data?.message;
        const isSessionExpired = errorMessage === 'Your session has been expired, please login again!';

        yield put(postInterviewFailure());

        if (e?.response?.status === 400 && !isSessionExpired) {
            toast.error(<Toast msg={errorMessage} />, {
                toastId: errorMessage,
            });
        } else if (!isSessionExpired) {
            toast.error(<Toast msg={errorMessage} />, {
                toastId: errorMessage,
            });
        }
    }
}
function* postBulkInterviewRequest(action) {
    const formData = new FormData();
    let descriptionType = typeof action?.payload?.data?.jobDescription;
    formData.append('position', action?.payload?.data?.position);
    formData.append('timeDuration', action?.payload?.data?.timeDuration); //this line is missing in prod code(II-311)
    formData.append('candidateTracker', action?.payload?.data?.candidateTracker);
    formData.append('resumeZip', action?.payload?.data?.resumeZip);
    if (descriptionType !== 'string') {
        formData.append('jobDescription', action?.payload?.data?.jobDescription);
    }
    if (action?.payload?.data?.panelist) {
        formData.append('panelist', action?.payload?.data?.panelist);
    }
    formData.append('guests', JSON.stringify(action?.payload?.data?.guests));
    formData.append('remarks', action?.payload?.data?.remarks);
    formData.append('skills', JSON.stringify(action?.payload?.data?.skills));
    formData.append('customScreeningQuestions', JSON.stringify(action?.payload?.data?.customScreeningQuestions));
    formData.append('customSkills', JSON.stringify(action?.payload?.data?.customSkills));
    formData.append('screeningQuestions', JSON.stringify(action?.payload?.data?.screeningQuestions));
    formData.append('domain', action?.payload?.data?.domain);
    formData.append('productId', action?.payload?.data?.productId);
    formData.append('confirm', action?.payload?.data?.confirm);
    try {
        const res = yield INTERVIEW.post(`interviews/bulkUpload`, formData);
        if (res.status === 201 || res.status === 200) {
            if (!res.data.result?.status && res.data.result?.error) {
                yield put(postBulkInterviewFailure());
                toast.error(<Toast msg={res.data.result?.error} />, {
                    autoClose: 5000,
                    toastId: res.data.result?.error,
                });
            } else {
                yield put(postBulkInterviewSuccess(res.data.result));
                yield call(action.payload.callback);
                if (action?.payload?.successMessage) {
                    toast.success(<Toast msg={action?.payload?.successMessage} />, {
                        autoClose: 5000,
                        toastId: action?.payload?.successMessage,
                    });
                }
            }
        }
    } catch (e) {
        if (e?.response?.status === 400) {
            yield put(postBulkInterviewFailure(e?.response?.data?.message));
        } else if (!e?.response?.status) {
            yield put(postBulkInterviewFailure());
            toast.error(<Toast msg="Please refresh the page and try again!" />, {
                toastId: 'Please refresh the page and try again!',
            });
        } else {
            yield put(postBulkInterviewFailure());
            yield e?.response?.data?.message &&
                e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
                toast.error(<Toast msg={e?.response?.data?.message} />, {
                    toastId: e?.response?.data?.message,
                });
        }
    }
}

function* getInterviewRequest(action) {
    const productId = localStorageJsonParseDecrypt('productId')?.productId;
    const isSearch = action.payload.search ? `&search=${encodeURIComponent(action.payload.search)}` : '';
    const isProduct = productId ? `&productId=${productId}` : '';
    const isProductV = productId ? `?productId=${productId}` : '';
    const isPageAndPageSize =
        action.payload.page && action.payload.pageSize
            ? `?page=${action.payload.page}&pageSize=${action.payload.pageSize}`
            : '';
    const isSort = action.payload.filters
        ? action.payload.filters.sort
            ? `&sort=${action.payload.filters.sort}`
            : action.payload.sort
              ? `&sort=${action.payload.sort}`
              : ''
        : action.payload.sort
          ? `&sort=${action.payload.sort}`
          : '';
    const isfilter = action.payload.filters
        ? (action.payload.filters.status ? `&status=${action.payload.filters.status}` : '') +
          (action.payload.filters.from
              ? action.payload.filters.to
                  ? `&from=${action.payload.filters.from}T00:00:00&to=${action.payload.filters.to}T23:59:59`
                  : `&from=${action.payload.filters.from}T00:00:00&to=3000-12-31T23:59:59`
              : action.payload.filters.to
                ? `&from=2000-01-01T00:00:00&to=${action.payload.filters.to}T23:59:59`
                : '') +
          (action.payload.filters.feedback ? `&feedback=${action.payload.filters.feedback}` : '') +
          (action.payload.filters.experience ? `&experience=${action.payload.filters.experience}` : `&experience=0`) +
          (action.payload.filters.createdBy ? `&createdBy=${action.payload.filters.createdBy}` : '') +
          (action.payload.filters.requestedSlots ? `&requestedSlots=${action.payload.filters.requestedSlots}` : '')
        : '';
    const isProductR = isPageAndPageSize && isProduct ? isProduct : isProductV;
    try {
        //
        const res = yield INTERVIEW.get(`interviews${isPageAndPageSize}${isSearch}${isfilter}${isProductR}${isSort}`);
        if (res.status === 200) {
            yield put(getInterviewSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* getInterviewByIdRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(getInterviewByIdSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewByIdFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* getIncVidVideoPermission() {
    try {
        const res = yield INTERVIEW.get(`interviews/checkVideoPermission`);
        if (res.status === 200) {
            yield put(incvidPlatformPermissionSuccess(res.data.result));
        }
    } catch (e) {
        yield put(incvidPlatformPermissionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* getInterviewStatRequest(action) {
    try {
        const res = yield INTERVIEW.post(`interviews/interviewStatsByDate`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(getInterviewStatSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewStatFailure());
    }
}

function* getPositionStatRequest(action) {
    if (!action.payload.position) {
        delete action.payload.position;
    }
    try {
        const res = yield INTERVIEW.post(`interviews/positionStatsByDate`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(getPositionStatSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPositionStatFailure());
    }
}

function* getPositionStatForBarGraphRequest(action) {
    if (!action.payload.position || action.payload.position === 'all') {
        delete action.payload.position;
    }
    try {
        const res = yield INTERVIEW.post(`interviews/positionStatsByDate`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(getPositionStatForBarGraphSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getPositionStatForBarGraphFailure());
    }
}

function* getInterviewByIdForPositionRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/calender/position/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(getInterviewByIdForPositionSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewByIdForPositionFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* updateInterviewStatusRequest(action) {
    try {
        const res = yield INTERVIEW.patch(`interviews/${action.payload.id}/status/${action.payload.status}`, {
            cancelReason: action.payload.reason,
        });
        if (res.status === 201) {
            yield put(updateInterviewStatusSuccess());
            yield call(action.payload.callback);
            toast.success(<Toast msg={'Interview Cancelled Successfully'} />);
        }
    } catch (e) {
        yield put(updateInterviewStatusFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* getFeedbackRequest(action) {
    try {
        const res = yield INTERVIEW.get(
            `interviews/${action.payload.interviewId}/feedback/${action.payload.feedbackId}`
        );
        if (res.status === 200) {
            yield put(getFeedbackSuccess(res.data?.result?.feedback));
        }
    } catch (e) {
        yield put(getFeedbackFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* getFeedbackReportRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviews/shortlist-feedback-report?interviewId=${action.payload.id}`);
        if (res.status === 200) {
            yield put(getFeedbackReportSuccess(res.data?.result));
            yield call(action.payload.callback, res.data?.result);
        }
    } catch (e) {
        yield put(getFeedbackReportFailure());
        yield e?.response?.data?.message &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* createCalendarInviteRequest(action) {
    yield put(createCalendarInviteSuccess());
    yield call(action?.payload?.callBack, 1);
    try {
        let res;
        if (action.payload.data.edit) {
            delete action.payload.data.edit;
            res = yield INTERVIEW.post('interviews/reschedule-interview', action.payload.data);
        } else {
            delete action.payload.data.edit;
            res = yield INTERVIEW.post(`interviews/${action.payload.id}/calendar-invite`, action.payload.data);
        }
        if (res.status === 200 || res.status === 201) {
            localStorageEncrypt('activeMeetLink', res?.data?.result?.meetInviteDetails?.meetingLink);
            yield put(createCalendarInviteSuccess());
            localStorageRemove('interviewId');
            yield call(action?.payload?.callBack, 2);
            yield toast.success(<Toast msg={'The invite has been sent successfully'} />);
        }
    } catch (e) {
        if (e?.response?.status === 500) {
            yield call(action.payload.callBack, e?.response?.status);
        } else {
            yield put(createCalendarInviteFailure());
            yield e?.response?.data?.message &&
                toast.error(<Toast msg={e?.response?.data?.message} />, {
                    toastId: e?.response?.data?.message,
                });
        }
    }
}

function* continueCalendarInviteRequest(action) {
    try {
        let res;
        delete action.payload.data.edit;
        res = yield INTERVIEW.post(`interviews/${action.payload.id}/calendar-invite`, action.payload.data);
        if (res.status === 201 || res.status === 200) {
            yield call(action.payload.callBack);
            yield put(continueCalendarInviteSuccess());
            yield toast.success(<Toast msg={'The invite has been sent successfully'} />);
        }
    } catch (e) {
        yield put(continueCalendarInviteFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}

function* checkCandidateExistsRequest(action) {
    try {
        const res = yield CANDIDATE.post(`candidates/checkExistingCandidate`, action.payload);
        if (res.status === 201) {
            yield put(checkCandidateExistsSuccess(res.data?.result?.duplicate));
            yield call(action.payload.callback, res.data?.result);
        }
    } catch (e) {
        yield put(checkCandidateExistsFailure());
        yield e?.response?.data?.message &&
            e?.response?.data?.message !== 'Your session has been expired, please login again!' &&
            toast.error(<Toast msg={e?.response?.data?.message} />, {
                toastId: e?.response?.data?.message,
            });
    }
}
function* resendEmailRequest(action) {
    try {
        const res = yield INTERVIEW.post(`/interviews/resendEmail`, action?.payload);
        if (res.status === 201 || res.status === 200) {
            yield put(resendEmailSuccess(res.data.result));
            toast.success(<Toast msg={'Email sent Successfully.'} />, {
                autoClose: 5000,
                toastId: 'Email sent Successfully.',
            });
            yield call(action?.payload?.callback);
        }
    } catch (e) {
        yield put(resendEmailFailure());
    }
}
function* getInterviewIncbotRequest(action) {
    try {
        const res = yield INTERVIEW_INCBOT.get(`getInterview/${action.payload.interviewId}/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getInterviewIncbotSuccess(res.data.result));
        } else yield put(getInterviewIncbotFailure());
    } catch (e) {
        yield put(getInterviewIncbotFailure());
    }
}

function* getInterviewDataRequest(action) {
    try {
        const res = yield INTERVIEW.get(
            `interviews/getFeedback/${action.payload.panelistId}/${action.payload.interviewId}`
        );
        if (res.status === 200) {
            yield put(getInterviewDataSuccess(res.data?.result));
            yield call(action.payload.callback, res.data?.result);
        }
    } catch (e) {
        yield put(getInterviewDataFailure());
    }
}

export function* watchPostInterviewAPI() {
    yield takeEvery(POST_INTERVIEW, postInterviewRequest);
}

export function* watchContinueCalendarInviteRequestAPI() {
    yield takeEvery(CONTINUE_CALENDAR_INVITATION, continueCalendarInviteRequest);
}

export function* watchPostBulkInterviewAPI() {
    yield takeEvery(POST_BULK_INTERVIEW, postBulkInterviewRequest);
}

export function* watchGetInterviewAPI() {
    yield takeEvery(GET_INTERVIEW, getInterviewRequest);
}

export function* watchGetInterviewStatAPI() {
    yield takeEvery(GET_INTERVIEW_STAT, getInterviewStatRequest);
}

export function* watchGetPositionStatAPI() {
    yield takeEvery(GET_POSITION_STAT, getPositionStatRequest);
}

export function* watchGetPositionStatForBarGraphAPI() {
    yield takeEvery(GET_POSITION_STAT_FOR_BAR_GRAPH, getPositionStatForBarGraphRequest);
}

export function* watchCheckCandidateExistsAPI() {
    yield takeEvery(CHECK_CANDIDATE_EXISTS, checkCandidateExistsRequest);
}

export function* watchGetInterviewByIdAPI() {
    yield takeEvery(GET_INTERVIEW_BY_ID, getInterviewByIdRequest);
}

export function* watchGetInterviewByIdForPositionAPI() {
    yield takeEvery(GET_INTERVIEW_BY_ID_FOR_POSITION, getInterviewByIdForPositionRequest);
}

export function* watchUpdateInterviewStatusAPI() {
    yield takeEvery(UPDATE_INTERVIEW_STATUS, updateInterviewStatusRequest);
}

export function* watchGetFeedbackAPI() {
    yield takeEvery(GET_FEEDBACK, getFeedbackRequest);
}

export function* watchGetFeedbackReportAPI() {
    yield takeEvery(GET_FEEDBACK_REPORT, getFeedbackReportRequest);
}

export function* watchCreateCalendarInviteAPI() {
    yield takeEvery(CREATE_CALENDAR_INVITATION, createCalendarInviteRequest);
}
export function* watchGetPanelistCalendarRequestAPI() {
    yield takeEvery(GET_PANELIST_CALENDAR_REQUEST, getPanelistCalendar);
}
export function* watchGetCandidateCalendarRequestAPI() {
    yield takeEvery(GET_CANDIDATE_CALENDAR_REQUEST, getCandidateCalendar);
}
export function* watchGetCandidateDetailsUsingResumeRequestAPI() {
    yield takeEvery(GET_CANDIDATE_DETAILS_USING_RESUME, getCandidateDetailsUsingResumeRequest);
}
export function* watchGetCustomFileDetailsUsingResumeRequestAPI() {
    yield takeEvery(ADD_CUSTOM_FILE, getCustomFileDetailsUsingResumeRequest);
}

export function* watchResendEmailAPI() {
    yield takeEvery(RESEND_EMAIL, resendEmailRequest);
}
export function* watchFetchResumeScoreApi() {
    yield takeEvery(FETCH_RESUME_SCORE, fetchResumeScoreRequest);
}
export function* watchIncVidVideoPermissionAPI() {
    yield takeEvery(GET_INCVID_PLATFORM_PERMISSION, getIncVidVideoPermission);
}
export function* watchGetInterviewIncbotAPI() {
    yield takeEvery(GET_INTERVIEW_INCBOT, getInterviewIncbotRequest);
}
export function* watchGetInterviewDataAPI() {
    yield takeEvery(GET_INTERVIEW_DATA, getInterviewDataRequest);
}
export default function* rootSaga() {
    yield all([
        watchResendEmailAPI(),
        watchPostInterviewAPI(),
        watchContinueCalendarInviteRequestAPI(),
        watchGetCustomFileDetailsUsingResumeRequestAPI(),
        watchPostBulkInterviewAPI(),
        watchGetInterviewAPI(),
        watchGetInterviewStatAPI(),
        watchGetPositionStatAPI(),
        watchGetPositionStatForBarGraphAPI(),
        watchUpdateInterviewStatusAPI(),
        watchGetFeedbackAPI(),
        watchCreateCalendarInviteAPI(),
        watchGetFeedbackReportAPI(),
        watchGetInterviewByIdAPI(),
        watchGetInterviewByIdForPositionAPI(),
        watchCheckCandidateExistsAPI(),
        watchGetPanelistCalendarRequestAPI(),
        watchGetCandidateCalendarRequestAPI(),
        watchGetCandidateDetailsUsingResumeRequestAPI(),
        watchFetchResumeScoreApi(),
        watchIncVidVideoPermissionAPI(),
        watchGetInterviewIncbotAPI(),
        watchGetInterviewDataAPI(),
    ]);
}
